import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";
import Cookies from 'universal-cookie';
import {GetUnauthorizedResources} from "../../../../api/SpineClient";

export default function BundleColorChoser() {

    const [loading, setLoading] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [productName, setProductName] = useState(null);
    const [selectedDisplayColor, setSelectedDisplayColor] = useState(null);
    const [coloredBoxImageUrl, setColoredBoxImageUrl] = useState(null);
    const history = useHistory();
    const [planVariantId, setPlanVariantId] = useState(null);
    const [componentId, setComponentId] = useState(null);
    const [discountId, setDiscountId] = useState(null);

    const [hardwareProducts, setHardwareProducts] = useState([]);
    const [selectedGtin, setSelectedGtin] = useState(null);

    document.title = Strings.PURCHASE_BUNDLE_BROWSER_TAB;
    window.location.href =  "https://tiger.media/kombi-pakete/";

    useEffect(() => {

        setLoading(true);

        const queryParams = new URLSearchParams(window.location.search);
        let planVariantId = queryParams.get("planVariantId");
        setPlanVariantId(planVariantId);
        setComponentId(queryParams.get("componentId"));
        setDiscountId(queryParams.get("discountId"));

        GetUnauthorizedResources('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId, {}, 'application/json', GlobalConstants.BILL_HOST)
            .then(r => {
                //In case of an error, redirect to the error page
                if (r.error || r.result == null) {
                    googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                    googleTagManagerWrapper.noPurchase();
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=2");
                    return null;
                }

                let product = r.result;

                if (product.type == null) {
                    googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                    googleTagManagerWrapper.noPurchase();
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=3");
                    return null;
                }
                setProductName(product.billwerkName);

                const cookies = new Cookies();
                let consentCookie = cookies.get('borlabs-cookie');
                if (consentCookie?.consents?.statistics?.includes("google-analytics")) {
                    googleTagManagerWrapper.consentGoogleAnalytics();
                }
            });
        GetUnauthorizedResources('api/purchases/hardware-products/search/by-product-name-and-availability?productName=tigerbox&available=true', {}, 'application/json', GlobalConstants.BILL_HOST)
            .then(response => {
                //In case of an error, redirect to the error page
                if (response.error || response.result == null) {
                    googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                    googleTagManagerWrapper.noPurchase();
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=22");
                    return null;
                }

                let hardwareProducts = response.result._embedded.hardwareProducts;
                setHardwareProducts(hardwareProducts);
                if (hardwareProducts.length > 0) {
                    let first = hardwareProducts[0];
                    setSelectedGtin(first.gtin);
                    setSelectedColor(first.productVariant);
                    setSelectedDisplayColor(first.displayProductVariant);
                    setColoredBoxImageUrl(first.productImageUrl);
                }
                setLoading(false);
            })

    }, [history]);


    const onChangeStep = (step) => {
        switch (step) {
            case 0:
                //Active step -> Do nothing
                return;
            case 1:
                return;
            case 2:
                return;
            case 3:
                return;
            case 4:
                return;
            default:
                return;
        }
    }

    const getHardwareProduct = (colorHexValue) => {
        for (const hardwareProduct of hardwareProducts) {
            if (hardwareProduct.colorHexValue === colorHexValue) {
                return hardwareProduct;
            }
        }
        return null;
    }

    const submitBundleColor = (e) => {
        e.preventDefault();
        googleTagManagerWrapper.colorChosed(selectedColor);
        history.push("/" + GlobalConstants.APP_PATH + 'purchase/' + planVariantId + '?selectedColor=' + selectedColor + "&componentId=" + componentId + (discountId ? "&discountId=" + discountId : ''));
    }

    function boxColorChanged(color) {
        let hardwareProduct = getHardwareProduct(color);
        if (hardwareProduct) {
            setSelectedGtin(hardwareProduct.gtin);
            setSelectedColor(hardwareProduct.productVariant);
            setSelectedDisplayColor(hardwareProduct.displayProductVariant);
            setColoredBoxImageUrl(hardwareProduct.productImageUrl);
        }
    }

    if (loading) {
        return <TigerSpinner />
    }

    return (

        <div className="color-choser-container purchase-container">
            <PurchaseStatus step={0} maxStep={0} bundled={true}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="color-choser-form-container">
                <div className="color-choser-info purchase-info" style={{marginBottom: "0", fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: productName}}/>
                <div className="color-choser-info purchase-info" style={{marginTop: "0"}} dangerouslySetInnerHTML={{__html: Strings.COLOR_CHOSER_INFO}}/>
                <div className="color-choser-box-image">
                    <img src={coloredBoxImageUrl} className={'colored-box-image'} alt={'tigerbox TOUCH'}/>
                </div>
                <div className="color-choser-colors">
                    {hardwareProducts.map(p => (
                        <div role={"button"} onClick={() => boxColorChanged(p.colorHexValue)}
                             key={p.gtin}
                             className={"color-choser-box" + (p.gtin === selectedGtin ? ' selected-color' : '')} style={{backgroundColor: p.colorHexValue}}>&nbsp;</div>
                    ))}
                </div>
                <div className="color-choser-info purchase-info" dangerouslySetInnerHTML={{__html: Strings.COLOR_CHOSER_SELECTED_COLOR.replace('{0}', selectedDisplayColor)}}/>
                <TigerButton className="w-100" variant="red" disabled={selectedColor == null}
                             type="button" onClick={(e) => submitBundleColor(e)}>
                    {Strings.PURCHASE_PROCEED_BUTTON}
                </TigerButton>
            </div>

        </div>
    )
}